import React from 'react'
import { Service } from '../../models'
import RequestDevisModal from '../../components/modals/RequestDevisModal'
const ServiceItem = ({ service, index }:{service: Service, index: number}) => {
    return (
        <>
            <div className="col-xl-4 col-md-12">
                <div className="card comp-card">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col">
                                <h5 className="m-b-20">{service.libelle}</h5>
                                <h3>{service.price}</h3>
                                <p className="m-b-0">
                                    {service.description}
                                </p>
                            </div>
                            <div className="col-auto">
                                <RequestDevisModal service={service} index={index} key={index} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServiceItem
