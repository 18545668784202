import React from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'

const ClientPage = () => {
    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES CLIENTS</h5>
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientPage
