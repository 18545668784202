/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import img from '../../assets/images/white-logo.png'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { toast } from 'react-toastify';
import { Client, Plan } from '../../models';
const InvoicePage = () => {

    const paymentIntent = new URLSearchParams(window.location.search).get('payment_intent');
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    const paymentStatus = new URLSearchParams(window.location.search).get('redirect_status');
    const [status, setStatus] = useState('pending');
    const [Loading, setLoading] = useState(true);
    const [client, setClient] = useState<Client>({} as Client);
    const [plan, setPlan] = useState<Plan>({} as Plan);
    const [paymentChecked, setPaymentChecked] = useState(false);
    const snap = useSnapshot(store);

    const printInvoice = () => {
        // Cibler les éléments que vous souhaitez imprimer
        const elementsToPrint = document.querySelectorAll('.printable');

        // Créer un élément de conteneur pour les éléments à imprimer
        const printContainer = document.createElement('div');
        elementsToPrint.forEach(element => {
            printContainer.appendChild(element.cloneNode(true)); // Clonez l'élément pour éviter de modifier l'élément original
        });

        // Ajouter le conteneur à la page (optionnel)
        document.body.appendChild(printContainer);

        // Imprimer la page
        window.print();

        // Supprimer le conteneur après l'impression (optionnel)
        document.body.removeChild(printContainer);
    }

    const checkInvoice = async() => {
        try {
            const res = await store.core.checkPaymentIntent({
                payment_intent_id: paymentIntent,
                client_secret: clientSecret,
            });
            if (res.success) {
                setStatus(res.result.status);
                setClient(res.result.client);
                setPlan(res.result.plan)
                toast.success(res.message);
            }else{
                toast.error(res.message);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            toast.error("Erreur de connexion à l'api");
            setLoading(false);
        }
    }

    useEffect(() => {
        const checkPayment =  async() => {
            if (!paymentChecked) {
                setTimeout(async () => {
                    await checkInvoice();
                    setPaymentChecked(true); // Met à true une fois que checkInvoice est exécuté
                }, 2500);
            }
        }
        checkPayment();
    }, [clientSecret, paymentIntent, paymentChecked])

    return (
        <>
            <div className="row">
                {/* [ sample-page ] start */}
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-body">
                            <ul className="nav nav-tabs profile-tabs border-bottom mb-3 d-print-none" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                        <i className="material-icons-two-tone me-2">receipt</i>Facture
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content" id=''>
                                {
                                    status === 'success' && !Loading  ? (
                                        <div className="tab-pane active show" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                            <div className="card border invoice-card printable" id='invoice'>
                                                <div className="card-header">
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-6">
                                                            <h5 className="mb-0">Facture # {paymentIntent} </h5>
                                                        </div>
                                                        <div className="col-sm-6 text-sm-end"><img src={img} width={80} alt="logo" className="img-fluid" /></div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row mb-3">
                                                        <div className="col-md-4">
                                                            <h4 className="mb-2"><b>SOLAYA AGENCY.</b></h4>
                                                            <p className="text-muted mb-1">Paris, France</p>
                                                            <p className="text-muted mb-1"><a href="mailto:demo@company.com">contact@solayaagency.fr</a></p>
                                                            <p className="text-muted">+33 (0)767 04 48 68</p>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-6">
                                                            <h4 className="mb-2"><b>CLIENT : {client.nom} {client.prenoms}</b></h4>
                                                            <p className="text-muted mb-1">{client.address},</p>
                                                            <p className="text-muted mb-1">{client.phone}</p>
                                                            <p className="text-muted mb-1"><a href={`mailto:${client.email}`}>{client.email}</a></p>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h4 className="mb-2"><b>Details de la commande :</b></h4>
                                                            <p className="text-muted mb-1">Date : November 14</p>
                                                            <p className="text-muted mb-1">status:
                                                                <span className="badge text-success border border-success rounded-pill f-12 align-baseline">Payé</span>
                                                            </p>
                                                            <p className="text-muted">Order Id : #{paymentIntent}</p>
                                                        </div>
                                                    </div>
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th>Description</th>
                                                                    <th className="text-end">Quantité</th>
                                                                    <th className="text-end">Montant</th>
                                                                    <th className="text-end">Total</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <div>
                                                                            <h5 className="mb-1">{plan.name}</h5>
                                                                            <p className="mb-0 text-muted">{plan.description}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-end">1</td>
                                                                    <td className="text-end">{plan.price} € </td>
                                                                    <td className="text-end">{plan.price} € </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="rounded p-3 mb-3 bg-light-primary">
                                                        <div className="row justify-content-end">
                                                            <div className="col-auto">
                                                                <div className="table-responsive">
                                                                    <table className="table table-borderless text-end mb-0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>Sous Total :</th>
                                                                                <td>{plan.price} € </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Taxes (1%) :</th>
                                                                                <td>{plan.price} € </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="pe-0 pt-0">
                                                                                    <hr className="mb-3 mt-0" />
                                                                                    <h5 className="text-primary m-r-10">Total :</h5>
                                                                                </td>
                                                                                <td className="ps-0 pt-0">
                                                                                    <hr className="mb-3 mt-0" />
                                                                                    <h5 className="text-primary">$ 4827.00</h5>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h5><b>Terms and Condition :</b></h5>
                                                    <p className="text-muted">
                                                        Votre abonnement au plan {plan.name} a été activé pour 1 mois à compter de ce jour.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="text-center d-print-none mt-5 mb-3">
                                                <button type="button" className="btn btn-print-invoice btn-primary m-b-10 m-r-10" onClick={() => printInvoice()} >Imprimer</button>
                                                <Link to={'dashboard/services'} type="button" className="btn btn-secondary m-b-10">
                                                    Retour
                                                </Link>
                                            </div>
                                        </div>
                                    ): Loading && (
                                        <>
                                            <p className="text-center">Loading...</p>
                                        </>                                    
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* [ sample-page ] end */}
            </div>

        </>
    )
}

export default InvoicePage
