import React, { useState,useEffect } from 'react'
import img from '../../assets/images/white-logo.png';
import AuthSlider from './AuthSlider';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import store from '../../stores/store';


type ResetPWDForm = {
    password: string;
    password_confirmation: string;
}

const ResetPassword = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<ResetPWDForm>();
    const [token, setToken] = useState('');
    const getResetToken = ()=>{
        let forgotData =  JSON.parse(localStorage.getItem('forgotPassword') as any) ;
        if(forgotData){
            console.log("forgot data",forgotData);
            setToken(forgotData.token)
        }else{
            window.location.href = "/"
        }
    }

    const onSubmit: SubmitHandler<ResetPWDForm> = async (data) => {

        let btn_signup = document.getElementById('btn_submit') as HTMLButtonElement;
        btn_signup.disabled = true;
        btn_signup.innerText = 'Réinitialisation du mot de passe en cours...';
        const { resetPassword } = store.auth;
        try {
            console.log("data",data);
            if (data.password === data.password_confirmation) {
                let payload = {
                    code: token,
                    password: data.password,
                    confirm_password: data.password_confirmation
                }
                const res = await resetPassword(payload);
                console.log("api res", res);
    
                if (res.success) {
                    toast.success(res.message);
                    //reset form
                    data = {
                        password: '',
                        password_confirmation: ''
                    }
                    setTimeout(() => {
                        navigate('/');
                    }, 3500);
                } else {
                    toast.error(res.message);
                    setTimeout(() => {
                        btn_signup?.removeAttribute('disabled');
                    }, 3500);
                    btn_signup.innerText = 'Réinitialiser maintenant';
                }
                
            } else {
                toast.error("Echec, vos mots de passe ne correspondent pas");
            }
            setTimeout(() => {
                btn_signup?.removeAttribute('disabled');
            }, 3500);
            btn_signup.innerText = 'Réinitialiser maintenant';
        } catch (error) {
            console.log("error occurred",error);
            toast.error("Erreur de connexion à l'api");
            setTimeout(() => {
                btn_signup?.removeAttribute('disabled');
            }, 3500);
            btn_signup.innerText = 'Réinitialiser maintenant';
        }
    }

    useEffect(() => {
        getResetToken();
    }, [])

    return (
        <>
            <div className="auth-main">
                <div className="auth-wrapper v2">
                    <div className="auth-form">
                        <div className="logo">
                        <img src={img} width={90} alt="logo" className="img-fluid brand-logo" />
                        </div>
                        <div className="card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="d-flex justify-content-center">
                                            <div className="auth-header text-center">
                                                <h2 className="text-secondary"><b>Réinitialiser votre mot de passe</b></h2>
                                                <p className="f-16 mt-2">Veuillez entrez un nouveau mot de passe svp !</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="password" className="form-control" id="floatingInput" placeholder="Mot de passe" {...register('password',{required:true})} />
                                        <label htmlFor="floatingInput">Mot de passe</label>
                                        {errors.password && <span className='text-danger'>Veuillez entrez votre mot de passe</span>}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="password" className="form-control" id="floatingInput1" placeholder="Comfirmer votre mot de passe" {...register('password_confirmation',{required: true})} />
                                        <label htmlFor="floatingInput1">Confirmation du mot de passe</label>
                                        {errors.password_confirmation && <span className='text-danger'>Veuillez confirmer votre mot de passe svp</span>}

                                    </div>
                                    <div className="d-grid mt-3">
                                        <button type="submit" id='btn_submit' className="btn btn-secondary">Réinitialiser maintenant</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <AuthSlider />
                </div>
            </div>

        </>
    )
}

export default ResetPassword
