/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState,useEffect } from 'react'
import img from '../../assets/images/white-logo.png';
import AuthSlider from './AuthSlider';
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';


type ForgotPassword = {
    user: any;
    token: string;
    expired_at?: string;
}

type VerifyOTPForm = {
    code1: string;
    code2: string;
    code3: string;
    code4: string;
}
const VerifyOTP = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<VerifyOTPForm>();


    const [data,setData] = useState<ForgotPassword>({
        user: null,
        token: '',
    });

    const getData = ()=>{
        let forgotData =  JSON.parse(localStorage.getItem('forgotPassword') as any) ;
        if(forgotData){
            console.log("forgot data",forgotData);
            setData({
                user: forgotData.user,
                token: forgotData.token,
            });
        }else{
            window.location.href = "/"
        }
    }

    const onSubmit: SubmitHandler<VerifyOTPForm> = async (fdata) => {
        console.log("data",fdata);
        const code = fdata.code1 + fdata.code2 + fdata.code3 + fdata.code4;
        // console.log("code",code);
        // console.log("token",data.token);
        
        if (code !== data?.token ) {
            toast.error("Vos codes ne correspondent pas");
        } else {
            toast.success("Code validé avec succès");
            setTimeout(() => {
                navigate('/auth/reset-password');
            }, 3500);
        }
    }

    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <div className="auth-main">
                <div className="auth-wrapper v2">
                    <div className="auth-form">
                        <div className="logo">
                        <img src={img} width={90} alt="logo" className="img-fluid brand-logo" />
                        </div>
                        <div className="card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="d-flex justify-content-center">
                                            <div className="auth-header">
                                                <h2 className="text-secondary"><b>Entrez votre code de vérification </b></h2>
                                                <h4 className="mt-2">
                                                    envoyé à votre adresse email
                                                </h4>
                                                <p className="f-16 mt-2">Nous avons envoyé un code à l'adresse: <strong>{data?.user?.email}</strong> </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row text-center">
                                        <div className="col">
                                            <input type="text" className="form-control" {...register('code1',{required: true})} />
                                        </div>
                                        <div className="col">
                                            <input type="text" className="form-control" {...register('code2',{required: true})} />
                                        </div>
                                        <div className="col">
                                            <input type="text" className="form-control" {...register('code3',{required: true})} />
                                        </div>
                                        <div className="col">
                                            <input type="text" className="form-control" {...register('code4',{required: true})} />
                                        </div>
                                    </div>
                                    <div className="d-grid mt-4">
                                        <button type="submit" id="btn_submit" className="btn btn-primary p-2">VALIDER</button>
                                    </div>
                                    <div className="d-flex mt-1 justify-content-between mt-4">
                                        <p className="text-muted">Aucun code reçu ? Veuillez verifier votre spam, ou</p>
                                        <p className="text-primary">Renvoyez un code</p>
                                    </div>
                                    <hr />
                                    <h5 className="d-flex justify-content-center">Vous n'avez pas reçu de mail? Veuillez vérifiez votre spam, ou</h5>
                                    <div className="d-grid mt-4">
                                        <button type="button" className="btn btn-outline-secondary bg-light text-secondary p-2">Renvoyé le code</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <AuthSlider />
                </div>
            </div>

        </>
    )
}

export default VerifyOTP
