/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import img from '../../assets/images/white-logo.png';
import AuthSlider from './AuthSlider';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm, SubmitHandler } from "react-hook-form";
import { RegisterForm } from '../../models';
import store from '../../stores/store';
import { useSnapshot } from 'valtio';

const Register = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<RegisterForm>();

    const snap = useSnapshot(store);
    const [isLoaded, setIsLoaded] = useState(false);
    const [prefix, setPrefix] = useState("");

    const handleCountryChange = (event: any) => {
        const selectedCountryId = Number(event.target.value);
        console.log("selectedCountryId", selectedCountryId);
        const selectedCountry = snap.core.getCountryById(selectedCountryId);
        console.log("country", selectedCountry);
        if (selectedCountry) {
            console.log('============selected country========================');
            console.log(selectedCountry);
            console.log('====================================');
            setPrefix(selectedCountry.prefix);
        } else {
            console.log("not found");

            setPrefix("");
        }
    };

    const onSubmit: SubmitHandler<RegisterForm> = async (data) => {
        console.log(data);
        let btn_signup = document.getElementById('btn_signup') as HTMLButtonElement;
        btn_signup.disabled = true;
        btn_signup.innerText = 'Inscription en cours...';

        const { register_user } = store.auth;
        try {
            const res = await register_user(data);
            console.log("api res", res);

            if (res.success) {
                toast.success(res.message);
                //reset form
                data = {
                    name: '',
                    prenoms: '',
                    email: '',
                    phone: '',
                    country_id: '',
                    password: '',
                    password_confirmation: ''
                }
                setTimeout(() => {
                    navigate('/dashboard');
                }, 3500);
            } else {
                toast.error(res.message);
                setTimeout(() => {
                    btn_signup?.removeAttribute('disabled');
                }, 3500);
                btn_signup.innerText = 'Se connecter';
            }
        } catch (error) {
            toast.error("Erreur de connexion à l'api");
            setTimeout(() => {
                btn_signup?.removeAttribute('disabled');
            }, 3500);
            btn_signup.innerText = 'Se connecter';
        }
    }

    const onResetForm = () => {
        console.log("reset form");

    }

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                store.core.getCountries(),
                // Ajoutez d'autres appels de fonction pour récupérer d'autres données de l'API ici
            ]);
            setIsLoaded(true);
        };
        if (!isLoaded) {
            fetchData();
        }
        return () => {
            // Code de nettoyage si nécessaire
        };
    }, [isLoaded]);

    return (
        <>
            <div className="auth-main">
                <div className="auth-wrapper v2">
                    <div className="auth-form">
                        <div className="logo">
                            <img src={img} width={90} alt="logo" className="img-fluid brand-logo" />
                        </div>
                        <div className="card">
                            <form onSubmit={handleSubmit(onSubmit)} onReset={onResetForm}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="d-flex justify-content-center">
                                            <div className="auth-header">
                                                <h2 className="text-secondary"><b>Inscrivez-vous</b></h2>
                                                <p className="f-16 mt-2">
                                                    Creer votre compte sur Solaya Agency dès maintenant !
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn mt-2 bg-light-primary bg-light text-muted" style={{ width: '100%' }}>
                                        <img src="https://berry.azurewebsites.net/assets/images/authentication/google-icon.svg" alt="google" />Inscrivez vous avec votre compte google
                                    </button>
                                    <div className="saprator mt-3">
                                        <span>OU</span>
                                    </div>
                                    <h5 className="my-4 d-flex justify-content-center">Créez votre compte sur Solaya </h5>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="prenoms" placeholder="Entrez votre prénoms" {...register("prenoms", { required: true })} />
                                                <label htmlFor="prenoms">Prénoms</label>
                                                {errors.prenoms && <span className='text-danger'>Veuillez entrez votre prénoms</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-floating mb-3">
                                                <input type="text" className="form-control" id="name" placeholder="Entrez votre Nom" {...register("name", { required: true })} />
                                                <label htmlFor="name">Nom</label>
                                                {errors.name && <span className='text-danger'>Veuillez entrez un nom</span>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" id="email" placeholder="Adresse Email" {...register("email", { required: true })} />
                                        <label htmlFor="email">Adresse Email</label>
                                        {errors.email && <span className='text-danger'>Veuillez entrez une adresse email valide</span>}
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="form-label">Pays</label>
                                        <div className="input-group">
                                            <span className="input-group-text">{prefix}</span>
                                            <select className="form-control" id="country_id" {...register('country_id', { required: true })} onChange={(event) => handleCountryChange(event)}>
                                                <option value="" defaultValue={''}>Choisir un pays</option>
                                                {snap.core.countries?.map((country) => (
                                                    <option key={country.id} value={country.id}>{country.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label htmlFor="phone">Téléphone</label>
                                        <div className="input-group">
                                            <span className="input-group-text">{prefix}</span>
                                            <input type="tel" className="form-control" id="phone" placeholder="Téléphone" {...register("phone", { required: false })} />
                                        </div>
                                        {errors.phone && <span className='text-danger'>Veuillez entrez une Téléphone valide</span>}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="password" className="form-control" id="password" placeholder="Votre mot de passe" {...register("password", { required: true })} />
                                        <label htmlFor="password">Mot de passe</label>
                                        {errors.password && <span className='text-danger'>Veuillez entrez un mot de passe valide</span>}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="password" className="form-control" id="password" placeholder="Votre mot de passe" {...register("password_confirmation", { required: true })} />
                                        <label htmlFor="password">Confirmation du Mot de passe</label>
                                        {errors.password && <span className='text-danger'>Veuillez confirmez votre mot de passe</span>}
                                    </div>
                                    <div className="form-check mt-3">
                                        <input className="form-check-input input-primary" type="checkbox" id="customCheckc1" defaultChecked />
                                        <label className="form-check-label" htmlFor="customCheckc1">
                                            <span className="h5 mb-0">J'accepte les <span>Termes &amp; Conditions.</span></span>
                                        </label>
                                    </div>
                                    <div className="d-grid mt-3">
                                        <button type="submit" id='btn_signup' className="btn btn-secondary">S'inscrire maintenant</button>
                                    </div>
                                    <hr />
                                    <Link to={'/'}>
                                        <h5 className="d-flex justify-content-center">Vous avez déjà un compte? connectez-vous ici</h5>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>

                    <AuthSlider />

                </div>
            </div>

        </>
    )
}

export default Register
