import React from 'react'

const AuthSlider = () => {
    return (
        <>
            <div className="auth-sidecontent">
                <div className="p-3 px-lg-5 text-center">
                    <div id="carouselExampleIndicators" className="carousel slide carousel-dark pointer-event" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <h1><b>FORMATIONS & ORIENTATION JURIDIQUE</b></h1>
                                <p className="f-12 mt-4">
                                    Des particuliers, des entreprises, et des associations
                                </p>
                            </div>
                            <div className="carousel-item">
                                <h1><b>ACCOMPAGNEMENT JURIDIQUE</b></h1>
                                <p className="f-12 mt-4">Des particuliers, des entreprises, et des associations</p>
                            </div>
                            <div className="carousel-item">
                                <h1><b>SOLUTIONS JURIDIQUES ET SOCIALES</b></h1>
                                <p className="f-12 mt-4">Libérez votre potentiel avec Solaya Agency ! Bénéficiez de nos services experts pour atteindre vos objectifs en toute confiance.</p>
                            </div>
                        </div>
                        <div className="carousel-indicators position-relative">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={0} className="active" aria-label="Slide 1" aria-current="true" />
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={1} aria-label="Slide 2" />
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={2} aria-label="Slide 3" />
                        </div>
                    </div>
                    <img src="https://berry.azurewebsites.net/assets/images/authentication/login2.svg" alt="images" className="img-fluid mt-3 w-75" />
                </div>
            </div>
        </>
    )
}

export default AuthSlider
