import React, { useEffect } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import PricingItem from '../../components/widgets/PricingItem'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
const PlanPage = () => {

    const snap = useSnapshot(store);
    const fetchPlans = async () => {
        await store.core.getPlans();
        console.log("plans", snap.core.plans);
    }
    useEffect(() => {
        const fetchData = async () => {
            await fetchPlans();
        }
        fetchData();
        // La fonction de nettoyage peut être vide ou contenir du code de nettoyage si sélection
        return () => {
            // Code de nettoyage si sélection
        }
    })

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">GESTION DES PLANS</h5>
                        </div>
                        <div className="card-body">
                            <p className="card-text"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {snap.core.plans &&
                    snap.core.plans.map((plan, index) => (
                        <div className="col-md-12 col-lg-6 col-xl-6 col-sm-12">
                            <PricingItem key={index} plan={plan} index={index} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default PlanPage
