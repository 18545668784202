/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form";
import { LoginForm } from '../../models';
import store from '../../stores/store';
import img from '../../assets/images/white-logo.png';
import AuthSlider from './AuthSlider';
import { Link } from 'react-router-dom';


const Login = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, watch, formState: { errors } } = useForm<LoginForm>();
    const [showPwd, setShowPwd] = useState(false);


    const onSubmit: SubmitHandler<LoginForm> = async(data) => {
        console.log(data);
        let btn_login = document.getElementById('btn_login') as HTMLButtonElement;
        btn_login.disabled = true;
        btn_login.innerText = 'Connexion en cours...';

        const { login } = store.auth;

        const res = await login(data);
        console.log("api res",res);
        
        if (res.success) {
            if (res.result.user.role_id === 2 ) {
                toast.success(res.message);
                setTimeout(() => {
                    navigate('/dashboard');
                }, 3500);
            }else{
                toast.warning("Vous n'avez pas le droit d'acceder à cette page");
                setTimeout(() => {
                    btn_login?.removeAttribute('disabled');
                }, 3500);
                btn_login.innerText = 'Se connecter';
            }
        } else {
            toast.error(res.message);
            setTimeout(() => {
                btn_login?.removeAttribute('disabled');
            }, 3500);
            btn_login.innerText = 'Se connecter';
        }

    };

    return (
        <>
            <div className="auth-main">
                <div className="auth-wrapper v2">
                    <div className="auth-form">
                        <div className="logo">
                            <img src={img} alt="image" width={90} className="img-fluid brand-logo" />
                        </div>
                        <div className="card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="d-flex justify-content-center">
                                            <div className="auth-header">
                                                <h2 className="text-secondary"><b>Bonjour, Bienvenue </b></h2>
                                                <p className="f-16 mt-2">Entrer vos identifiants pour continuer</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" className="btn mt-2 bg-light-primary bg-light text-muted" style={{ width: '100%' }}>
                                        <img src="https://berry.azurewebsites.net/assets/images/authentication/google-icon.svg" alt="image" />Connectez-vous avec votre compte google
                                    </button>
                                    <div className="saprator">
                                        <span>OU</span>
                                    </div>
                                    <h5 className="my-4 d-flex justify-content-center">Connectez vous à votre espace utilisateur</h5>
                                    <div className="form-floating mb-3">
                                        <input type="text" className="form-control" id="floatingInput" placeholder="Email address / Username"  {...register("username", { required: true })} />
                                        <label htmlFor="floatingInput">Email address / Username</label>
                                        {errors.username && <span className='text-danger'>Veuillez entrez un nom d'utilisateur</span>}
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type={showPwd ? 'text': 'password' } className="form-control" id="floatingInput1" placeholder="Password" {...register("password", { required: true })} />
                                        <label htmlFor="floatingInput1">Mot de passe</label>
                                        {errors.password && <span className='text-danger'>Veuillez entrez votre mot de passe</span>}
                                    </div>
                                    <div className="d-flex mt-1 justify-content-between">
                                        <div className="form-check">
                                            <input className="form-check-input input-primary" type="checkbox" id="customCheckc1" onChange={() => setShowPwd(!showPwd)} />
                                            <label className="form-check-label text-muted" htmlFor="customCheckc1">Affichez le mot de passe</label>
                                        </div>
                                        <Link to={'auth/forgot-password'}>
                                            <h5 className="text-secondary">Mot de passe oublié ?</h5>
                                        </Link>
                                    </div>
                                    <div className="d-grid mt-4">
                                        <button type="submit" id='btn_login' className="btn btn-secondary">Se connecter</button>
                                    </div>
                                    <hr />
                                    <Link to={'auth/register'}>
                                        <h5 className="d-flex justify-content-center">Vous n'avez pas de compte?, créez en un maintenant</h5>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <AuthSlider />
                    
                </div>
            </div>

        </>
    )
}

export default Login
