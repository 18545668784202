/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Service } from '../../models'
import { toast } from 'react-toastify';
import { useForm, SubmitHandler } from "react-hook-form";
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
type RequestDevisForm = {
    service_id: number;
    message: string;
    country_id: string;
    name?: string;
}
const RequestDevisModal = ({ service, index }: { service: Service, index: number }) => {

    const { register, handleSubmit, formState: { errors } } = useForm<RequestDevisForm>();
    const snap = useSnapshot(store);
    const [show, setShow] = React.useState(false)

    const onClose = () => setShow(false)
    const showModal = () => setShow(true)



    const onSubmit: SubmitHandler<RequestDevisForm> = async (data) => {
        console.log(data);
        let btn_submit = document.getElementById('btn_submit') as HTMLButtonElement;
        let btn_close = document.getElementById('btn_clode') as HTMLButtonElement
        btn_submit.disabled = true;
        btn_submit.innerText = 'Demande en cours...';

        const { requestDevis } = store.core;
        try {
            const res = await requestDevis(data);
            console.log("api res", res);

            if (res.success) {
                toast.success(res.message);
            } else {
                toast.error(res.message);
            }
            setTimeout(() => {
                btn_submit?.removeAttribute('disabled');
                //dismiss modal
                window.location.reload()
            }, 3500);
            btn_submit.innerText = 'Envoyé la demande';
        } catch (error) {
            toast.error("Erreur de connexion à l'api");
            setTimeout(() => {
                btn_submit?.removeAttribute('disabled');
            }, 3500);
            btn_submit.innerText = 'Envoyé la demande';
        }
    }

    return (
        <>
            {/* [ varying-modal ] start */}
            <button className='btn btn-outline-primary' data-bs-toggle="modal" data-bs-target={`#exampleModal_${index}`}   >
                <i className="fas fa-shopping-cart bg-light-primary text-primary" />
            </button>
            <div className="modal fade" id={`exampleModal_${index}`} tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <form onSubmit={handleSubmit(onSubmit)} >
                            <div className="modal-header">
                                <h3 className="modal-title" id="exampleModalLabel">DEMANDE DE DEVIS </h3>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="modal-body">
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Devis:</label>
                                    <input type="text" className="form-control" id="recipient-name" defaultValue={service.libelle} readOnly  {...register('name', { required: true })} />
                                    <input type="text" className="form-control" id="recipient-name" defaultValue={service.id} hidden readOnly  {...register('service_id', { required: true })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="recipient-name" className="col-form-label">Pays de résidence:</label>
                                    <select id="country_id" className="form-control" {...register('country_id', { required: true })} >
                                        {snap.core.countries?.map((item) => (
                                            <option value={item.id} key={item.id} >{item.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message" className="col-form-label">Description de la demande:</label>
                                    <textarea className="form-control" id="message" defaultValue={""} placeholder="Donnez une brève description de votre demande de devis" {...register('message', { required: false })} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id='btn_close' className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                <button type="submit" id='btn_submit' className="btn btn-primary">Envoyé la demande</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* [ varying-modal ] end */}
        </>
    )
}

export default RequestDevisModal
