/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './style.css';
import {Link} from 'react-router-dom';
import img from '../../../assets/images/white-logo.png';

const Sidebar = () => {
    return (
        <>
            {/* [ Sidebar Menu ] start */}
            <nav className="pc-sidebar">
                <div className="navbar-wrapper">
                    <div className="m-header">
                        <a href="https://solayaagency.fr" className="b-brand">
                            {/* ========   Change your logo from here   ============ */}
                            <img src={img} alt="logo" className="logo logo-lg" width={50} />
                        </a>
                    </div>
                    <div className="navbar-content">
                        <ul className="pc-navbar">
                            <li className="pc-item pc-caption">
                                <label>TABLEAU DE BORD</label>
                                <i className="ti ti-dashboard" />
                            </li>
                            <li className="pc-item">
                                <Link to="/dashboard" className="pc-link active"  >
                                    <span className="pc-micon"><i className="ti ti-dashboard" /></span><span className="pc-mtext">Dashboard</span>
                                </Link>
                            </li>
                            
                            <li className="pc-item pc-caption">
                                <label>NOS PLANS & SERVICES</label>
                                <i className="ti ti-layout" />
                            </li>
                            <li className="pc-item">
                                <Link to="/dashboard/plans" className="pc-link">
                                    <span className="pc-micon"><i className="ti ti-layout-sidebar" /></span><span className="pc-mtext">Nos Plans</span>
                                </Link>
                            </li>
                            <li className="pc-item">
                                <Link to="/dashboard/services" className="pc-link">
                                    <span className="pc-micon"><i className="ti ti-layout-sidebar" /></span><span className="pc-mtext">Nos services</span>
                                </Link>
                            </li>
                            <li className="pc-item">
                                <Link to="/dashboard/subscriptions" className="pc-link">
                                    <span className="pc-micon"><i className="ti ti-layout-sidebar" /></span><span className="pc-mtext">Mes Souscriptions</span>
                                </Link>
                            </li>
                            {/* <li className="pc-item">
                                <Link to="/dashboard/payments" className="pc-link">
                                    <span className="pc-micon"><i className="ti ti-layout-sidebar" /></span><span className="pc-mtext">Mes Paiements</span>
                                </Link>
                            </li> */}
                            
                            {/* <li className="pc-item pc-caption">
                                <label>BLOG</label>
                                <i className="ti ti-layout-kanban" />
                            </li>
                            <li className="pc-item">
                                <Link to="/dashboard/categories" className="pc-link">
                                    <span className="pc-micon"><i className="ti ti-layout-sidebar" /></span><span className="pc-mtext">Catégories</span>
                                </Link>
                            </li>
                            <li className="pc-item">
                                <Link to="/dashboard/posts" className="pc-link">
                                    <span className="pc-micon"><i className="ti ti-layout-sidebar" /></span><span className="pc-mtext">Actualités</span>
                                </Link>
                            </li> */}
                        </ul>
                        <div className="pc-navbar-card bg-primary rounded">
                            <h4 className="text-white">
                                <i className="ti ti-package" />
                            </h4>
                            <p className="text-white opacity-75">SOLAYA AGENCY</p>
                            <a href="https://solayaagency.fr" target="_blank" className="btn btn-light text-primary">Décourez</a>
                        </div>
                        <div className="w-100 text-center">
                            <div className="badge theme-version badge rounded-pill bg-light text-dark f-12" />
                        </div>
                    </div>
                </div>
            </nav>
            {/* [ Sidebar Menu ] end */}

        </>
    )
}

export default Sidebar
