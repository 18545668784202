import { proxy } from 'valtio';
import authStore from './auth/authStore';
import coreStore from './core/coreStore';
// import userStore from './admin/userStore';
// import coreStore from './admin/coreStore';
const store = proxy({
    auth: authStore,
    core: coreStore,
});

export default store;