/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from "../../stores/store";
import { useSnapshot } from 'valtio';
import { toast } from "react-toastify";
import CheckoutForm from "./CheckoutForm";

const stripePromise = loadStripe("pk_live_51O3zNmEZgDw2Um6Mv67bQvS4WzR8sIrcRSA5lvOusTFxu80ql2MEU1Nclfnh7DBgVcJOfrqjpM8mi1s5UdmKeGW200crKCkO34")

const PaymentPage = () => {

    const [clientSecret, setClientSecret] = useState("");
    const snap = useSnapshot(store);

    const createIntent = async () => {
        let data = {
            amount: 1000,
            customer: "Bobo",
            description: "Paiement du service 1",
        }
        const res = await store.core.createPaymentIntent(data);

        console.log('==============api response======================');
        console.log(res);
        console.log('====================================');
        if (res.success) {
            setClientSecret(res.result.client_secret);
        } else {
            toast.error(res.message);
        }
    }

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        const initPayment = async () => {
            await createIntent();
        }
        initPayment();
    }, [])

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance
    } as any;

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">EFFECTUER UN NOUVEAU PAIEMENT</h5>
                        </div>
                        <div className="card-body">
                        {clientSecret && (
                            <Elements options={options} stripe={stripePromise}>
                                <CheckoutForm />
                            </Elements>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentPage
