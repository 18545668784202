import React from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store'
import { useSnapshot } from 'valtio';

const SubscriptionPage = () => {

    const snap = useSnapshot(store);
    const subscriptions = snap.core.subscriptions;

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">MES SOUSCRIPTIONS</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nom</th>
                                            <th scope="col">Prix</th>
                                            <th scope="col">Date d'achat</th>
                                            <th scope="col">Date Debut</th>
                                            <th scope="col">Date Fin</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { subscriptions &&
                                            subscriptions.map((subscription, index) => (
                                                <tr key={index}>
                                                    <td>{subscription.plan?.name}</td>
                                                    <td>{subscription.price}</td>
                                                    <td>{subscription.created_at}</td>
                                                    <td>{subscription.start_date}</td>
                                                    <td>{subscription.end_date}</td>
                                                    <td>{subscription.status}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPage
