/* eslint-disable @typescript-eslint/no-unused-vars */
import { proxy } from 'valtio';
import type { User, Response, } from '../../models';
import { postData, postDataWithToken } from '../../helpers/http';
import { setCookie } from '../../helpers/utils';

class AuthStore {
    isAuthenticated: boolean = false;
    user: User = {} as User;
    token: string = '';

    constructor() {
        // Récupérer l'état initial du localStorage lors de la création de l'instance
        const storedState = localStorage.getItem('authStoreState');
        if (storedState) {
            const initialState = JSON.parse(storedState);
            this.isAuthenticated = initialState.isAuthenticated;
            this.user = initialState.user;
            this.token = initialState.token;
        }

        // Assurer que les méthodes sont liées à l'instance actuelle
        this.setAuthState = this.setAuthState.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.register_user = this.register_user.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    private setAuthState(user: User, token: string): void {
        this.isAuthenticated = true;
        this.user = user;
        this.token = token;

        // Mettre à jour localStorage lors du changement d'état
        localStorage.setItem('authStoreState', JSON.stringify(this));
    }

    async login(payload: any): Promise<Response> {
        try {
            const response = await postData('auth/login', payload);
            const result = response.result;
            console.log("api response", response);

            if (response.success && response.result.user.role_id === 2) {
                // setCookie('token', result.token);
                localStorage.setItem('tokenExpiration', result.expired_at);
                document.cookie = 'token=' + result.token + '; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
                setCookie('token',result.token)
                this.setAuthState(result.user, result.token);
            }
            return response;
        } catch (error) {
            console.log("api error", error);
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async register_user(payload: any): Promise<Response> {
        try {
            const response = await postData('auth/register', payload);
            if (response.success) {
                const token = response.result.token;
                const user = response.result.user;
                this.setAuthState(user, token);
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async forgotPassword(payload: any): Promise<Response> {
        try {
            const response = await postData('auth/forgot-password', payload);
            if (response.success) {
                const data = {
                    user: response.result.user,
                    token: response.result.secret_code,
                    expired_at: response.result.expired_at
                };
                localStorage.setItem('forgotPassword', JSON.stringify(data));
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async resetPassword(payload: any): Promise<Response> {
        try {
            const response = await postData('auth/reset-password', payload);
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }

    async logout(): Promise<Response> {
        try {
            const response = await postDataWithToken('auth/logout');
            if (response.success) {
                this.isAuthenticated = false;
                this.user = {} as User;
                this.token = '';
                // Supprimer l'état du localStorage lors de la déconnexion
                localStorage.clear();
            }
            return response;
        } catch (error) {
            return {
                success: false,
                message: 'Erreur de connexion',
                result: null,
                errors: "Erreur de connexion à l'api",
            };
        }
    }
}

const authStore = proxy(new AuthStore());

export default authStore;
