/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import store from '../../stores/store'
import { useSnapshot } from 'valtio'
const RequestDevis = () => {

    const snap = useSnapshot(store);
    const devis = snap.core.devis

    const getStatus = (status: string) =>{
        let text = "";
        switch (status) {
            case 'pending':
                text = "EN ATTENTE"
                break;
            case 'processing':
                text = "EN COURS"
                break;
            case 'completed':
                text = "PAYER"
                break;
            default:
                break;
        }
        return text;
    }

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="row mb-3 align-items-center">
                        <div className="col">
                            <h4>Mes demandes de services</h4>
                        </div>
                        <div className="col-auto"> </div>
                    </div>
                    {devis && devis.map((devis, index) => (
                        <div className="rounded bg-light-secondary overflow-hidden mb-3" key={index}>
                            <div className="px-3 pt-3">
                                <div className="row mb-1 align-items-start">
                                    <div className="col">
                                        <h5 className="text-secondary mb-0">{devis.libelle}</h5>
                                        <small className="text-muted">{devis.created_at}</small>
                                    </div>
                                    <div className="col-auto">
                                        <h4 className="mb-0">{getStatus(devis.status)}</h4>
                                        {devis.status !== 'pending' && devis.status !== 'cancelled' && (
                                            <button className='btn btn-primary' >
                                                Consulter
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    
                    <div className="text-center">
                        <a href="#!" className="b-b-primary text-primary">View all <i className="ti ti-chevron-right" /></a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RequestDevis
