import React from 'react'
import img from '../../assets/images/white-logo.png';
import AuthSlider from './AuthSlider';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form";
import store from '../../stores/store';
import { toast } from 'react-toastify';

type ForgotPasswordForm = {
    username: string;
}

const ForgotPassword = () => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<ForgotPasswordForm>();

    const onSubmit: SubmitHandler<ForgotPasswordForm> = async (data) => {

        let btn_signup = document.getElementById('btn_signup') as HTMLButtonElement;
        btn_signup.disabled = true;
        btn_signup.innerText = 'Demande en cours...';
        const { forgotPassword } = store.auth;
        try {
            console.log("data",data);
            const res = await forgotPassword(data);
            console.log("api res", res);

            if (res.success) {
                toast.success(res.message);
                //reset form
                data = {
                    username: '',
                }
                setTimeout(() => {
                    navigate('/auth/verify-otp');
                }, 3500);
            } else {
                toast.error(res.message);
                setTimeout(() => {
                    btn_signup?.removeAttribute('disabled');
                }, 3500);
                btn_signup.innerText = 'Envoyer';
            }
        } catch (error) {
            console.log("error occurred",error);
            toast.error("Erreur de connexion à l'api");
            setTimeout(() => {
                btn_signup?.removeAttribute('disabled');
            }, 3500);
            btn_signup.innerText = 'Envoyer';
        }
    }

    return (
        <>
            <div className="auth-main">
                <div className="auth-wrapper v2">
                    <div className="auth-form">
                        <div className="logo">
                        <img src={img} width={90} alt="logo" className="img-fluid brand-logo" />
                        </div>
                        <div className="card">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="d-flex justify-content-center">
                                            <div className="auth-header text-center">
                                                <h2 className="text-secondary"><b>Mot de passe oublié?</b></h2>
                                                <p className="f-16 mt-2">
                                                    Entrez votre adresse email et nous vous enverrons un code OTP pour reinitialiser votre mot de passe
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="email" className="form-control" id="floatingInput" placeholder="Email Address / Username" {...register("username", { required: true })}  />
                                        <label htmlFor="floatingInput">Email Address / Username</label>
                                        {errors.username && <span className='text-danger'>Veuillez entrez votre adresse email ou votre nom d'utilisateur</span>}
                                    </div>
                                    <div className="d-grid mt-3">
                                        <button type="submit" id="btn_signup" className="btn btn-secondary">Envoyé</button>
                                    </div>
                                    <hr />
                                    <Link to={'/'}>
                                        <h5 className="d-flex justify-content-center">Vous avez déjà un compte? connectez-vous ici</h5>
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                    <AuthSlider />
                </div>
            </div>

        </>
    )
}

export default ForgotPassword
