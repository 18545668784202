import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate, } from 'react-router-dom';
import MainLayout from './components/layouts/MainLayout';
import Login from './pages/auth/Login';
import Dashboard from './pages/dashboard/Dashboard';
import AnalyticPage from './pages/dashboard/AnalyticPage';
import UsersPage from './pages/users/UsersPage';
import ActivityPage from './pages/users/ActivityPage';
import ClientPage from './pages/clients/ClientPage';
import ContactPage from './pages/clients/ContactPage';
import PlanPage from './pages/plans/PlanPage';
import ServicesPage from './pages/services/ServicesPage';
import SubscriptionPage from './pages/subscriptions/SubscriptionPage';
import PaymentPage from './pages/payments/PaymentPage';
import CategoryPage from './pages/blog/CategoryPage';
import PostPage from './pages/blog/PostPage';
import Register from './pages/auth/Register';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import VerifyOTP from './pages/auth/VerifyOTP';
import CheckoutPage from './pages/plans/CheckoutPage';
import InvoicePage from './pages/payments/InvoicePage';
import { checkAuthUser } from './helpers/utils';

const ProtectedRoute = ({ element, ...rest }: { element: any }) => {
    const isAuthenticated = checkAuthUser(); // Vérifie si l'utilisateur est authentifié
    return isAuthenticated ? element : <Navigate to="/" replace />;
};

const AppRoutes = () => {
    return (
        <React.Fragment>
            <Router>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path='/auth'>
                        <Route path="register" element={<Register />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="verify-otp" element={<VerifyOTP />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                    </Route>
                    <Route path="/dashboard" element={<ProtectedRoute element={<MainLayout />} />}  >
                        {/* Routes protégées dans le MainLayout */}
                        <Route index element={<Dashboard />} />
                        <Route path="analytics" element={<AnalyticPage />} />
                        <Route path="users" element={<UsersPage />} />
                        <Route path="users-activities" element={<ActivityPage />} />
                        <Route path="clients" element={<ClientPage />} />
                        <Route path="contacts" element={<ContactPage />} />
                        <Route path='plans'>
                            <Route index element={<PlanPage />} />
                            <Route path="checkout/:plan_id" element={<CheckoutPage />} />
                            <Route path="invoice" element={<InvoicePage />} />
                        </Route>
                        <Route path="services" element={<ServicesPage />} />
                        <Route path="subscriptions" element={<SubscriptionPage />} />
                        <Route path="payments" element={<PaymentPage />} />
                        <Route path="posts" element={<PostPage />} />
                        <Route path="categories" element={<CategoryPage />} />
                    </Route>
                </Routes>
            </Router>
        </React.Fragment>
    )
}

export default AppRoutes
