import React, { useEffect } from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import ServiceItem from './ServiceItem';
const ServicesPage = () => {

    const snap = useSnapshot(store);

    const fetchServices = async () => {
        const res = await store.core.getServices();
        console.log("api res",res);
    }

    const fetchCountries = async()=>{
        await snap.core.getCountries();
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchServices();
            await fetchCountries();
        }
        fetchData();
        // La fonction de nettoyage peut être vide ou contenir du code de nettoyage si sélection
        return () => {
            // Code de nettoyage si sélection
        }
    })

    return (
        <div>
            <BreadCrumb />
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">NOS SERVICES</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                {snap.core.services &&
                    snap.core.services.map((service, index) => (
                        <ServiceItem key={index} service={service} index={index} />
                    ))
                }
            </div>
        </div>
    )
}

export default ServicesPage
