/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";


const CheckoutForm = () => {

    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);

    const getInvoiceUrl =  () => {
        let hostname = window.location.hostname; 
        if (hostname === 'localhost') {
            return 'http://localhost:3000/dashboard/plans/invoice'
        }else{
            return `http://${hostname}/dashboard/plans/invoice`
        }
    }
    // payment_intent=pi_3OiBF4EZgDw2Um6M0OCJjXeQ&payment_intent_client_secret=pi_3OiBF4EZgDw2Um6M0OCJjXeQ_secret_WYWDx5dEod0qgZw3L1Oe88nxU&redirect_status=succeeded
    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setIsLoading(true);
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: getInvoiceUrl(),
            },
            
        })

        if (error?.type === "card_error" || error?.type === "validation_error") {
            setMessage(String(error.message));
        } else {
            setMessage("Une erreur s'est produite.");
        }
        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    } as any;

    useEffect(() => {
        if (!stripe) {
            return;
        }
        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent?.status) {
                case "succeeded":
                    setMessage("Payment succeeded!");
                    break;
                case "processing":
                    setMessage("Votre paiement est en cours...");
                    break;
                case "requires_payment_method":
                    setMessage("Your payment was not successful, please try again.");
                    break;
                default:
                    setMessage("Something went wrong.");
                    break;
            }
        });
    }, [stripe]);

    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit}>

                <PaymentElement id="payment-element" options={paymentElementOptions} />
                <button disabled={isLoading || !stripe || !elements} id="submit" className='btn btn-primary mt-5'>
                    <span id="button-text">
                        {isLoading ? <div className="spinner" id="spinner"></div> : "Payer maintenant"}
                    </span>
                </button>
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
            </form>
        </>
    )
}

export default CheckoutForm
