import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import Footer from './footer/Footer';
import { checkAuthUser } from '../../helpers/utils';

const MainLayout = () => {
    useEffect(() => {
        const isUserAuthenticated = checkAuthUser();
        if (!isUserAuthenticated) {
            // Gérer la redirection ou l'action lorsque l'utilisateur n'est pas authentifié
            // Par exemple, rediriger vers la page de connexion
            // history.push('/login');
            console.log("L'utilisateur n'est pas authentifié.");
            window.location.href = '/';
        }
    }, []);

    return (
        <>
            <Sidebar />
            <Topbar />
            <div className='pc-container'>
                <div className="pc-content">
                    {/* [ Main Content ] start */}
                    <div className="row">
                        <Outlet />
                    </div>
                    {/* [ Main Content ] end */}
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MainLayout;
