/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BreadCrumb from '../../components/layouts/BreadCrumb'

const ContactPage = () => {
    return (
        <div>
            <BreadCrumb />
            <div className="row">
                {/* [ sample-page ] start */}
                <div className="col-sm-12">
                    
                </div>
                {/* [ sample-page ] end */}
            </div>

        </div>
    )
}

export default ContactPage
