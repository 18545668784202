/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from 'react'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import RequestDevis from './RequestDevis';
const Dashboard = () => {

    const snap = useSnapshot(store);
    const user = snap.auth.user;
    const [show, setShow] = useState(false)

    const fetchClientData = async () =>{
        const res = await snap.core.getClientData();
        console.log("api response",res);
        
        if (res.success) {
            setShow(true);
        }
    }

    useEffect(()=>{
        const getData = async()=>{
            await fetchClientData()
        }
        getData()
    },[])

    return (
        <>
            {/* [ Main Content ] start */}
            <div className="row">
                {/* [ sample-page ] start */}
                <div className="col-xl-8 col-md-12">
                    <div className="card bg-secondary-dark dashnum-card text-white overflow-hidden">
                        <span className="round small" />
                        <span className="round big" />
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <div className="avtar avtar-lg">
                                        <i className="text-white ti ti-user" />
                                    </div>
                                </div>
                                <div className="col-auto">
                                </div>
                            </div>
                            <span className="text-white d-block f-34 f-w-500 my-2">
                                BONJOUR {user?.name} 
                                {/* <i className="ti ti-arrow-up-right-circle opacity-50" /> */}
                            </span>
                            <p className="mb-0 opacity-50">{user.email}</p>
                        </div>
                    </div>
                </div>
                
                <div className="col-xl-4 col-md-12">
                    <div className="card bg-primary-dark dashnum-card dashnum-card-small text-white overflow-hidden">
                        <span className="round bg-primary small" />
                        <span className="round bg-primary big" />
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                                <div className="avtar avtar-lg">
                                    <i className="text-white ti ti-credit-card" />
                                </div>
                                <div className="ms-2">
                                    <h4 className="text-white mb-1"> <i className="ti ti-arrow-up-right-circle opacity-50" />
                                        {snap.core.subscriptions && snap.core.subscriptions[0]?.plan?.name}
                                    </h4>
                                    <p className="mb-0 opacity-50 text-sm">Mon plan</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card dashnum-card dashnum-card-small overflow-hidden">
                        <span className="round bg-warning small" />
                        <span className="round bg-warning big" />
                        <div className="card-body p-3">
                            <div className="d-flex align-items-center">
                                <div className="avtar avtar-lg bg-light-warning">
                                    <i className="text-warning ti ti-credit-card" />
                                </div>
                                <div className="ms-2">
                                    <h4 className="mb-1"> <i className="ti ti-arrow-up-right-circle opacity-50" /></h4>
                                    <p className="mb-0 opacity-50 text-sm">Total Revenue</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-3 align-items-center">
                                <div className="col">
                                    <small>Historiques de paiements</small>
                                </div>
                                <div className="col-auto">
                                    <select className="form-select p-r-35">
                                        <option>Aujourd'hui</option>
                                        <option defaultValue={'month'}>Ce mois</option>
                                        <option>Cette année</option>
                                    </select>
                                </div>
                            </div>
                            <div id="growthchart" />
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-md-12">
                    <RequestDevis />
                </div>
                {/* [ sample-page ] end */}
            </div>
            {/* [ Main Content ] end */}

        </>
    )
}

export default Dashboard
