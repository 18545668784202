/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React,{useEffect, useState} from 'react';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {Link, useParams} from 'react-router-dom'
import store from '../../stores/store';
import { useSnapshot } from 'valtio';
import { Plan } from '../../models';
import { toast } from 'react-toastify';
import CheckoutForm from '../payments/CheckoutForm';

const stripePromise = loadStripe("pk_test_51O3zNmEZgDw2Um6M6XRRwrROnaO7gh98gt6hGNz4U8HyfceD4sCTQ9dDfJAFFqtByJ2XxbxYWRN1VbN6XzAnXurd00I5sbRS0l")

const CheckoutPage = () => {

    const [clientSecret, setClientSecret] = useState("");
    const plan_id = Number(useParams().plan_id)
    const snap = useSnapshot(store);
    const [plan, setPlan] = useState({} as Plan);
    const [show, setShow] = useState(false);

    const hostname = window.location.hostname + window.location.pathname;
    

    const fetchPlan = () => {
        const plans = snap.core.plans;
        console.log("plans",plans);
        
        const item = plans.find((p: Plan) => p.id === plan_id);
        if (item?.id === plan_id) {
            console.log('===========plan item=========================');
            console.log(item);
            console.log('====================================');
            setPlan(item);
        }
    }

    const createIntent = async () => {
        let btn_order = document.getElementById('btn_order') as HTMLButtonElement;
        btn_order.disabled = true;
        btn_order.innerText = 'En cours...';
        try {
            const user = snap.auth.user;
            let data = {
                amount: Math.ceil(Number(plan.price)),
                customer: user.name ,
                description: "Souscription au plan " + plan.name, 
                metadata:{
                    plan_id: plan.id,
                    user_id: user.id,
                    customer: user.name
                }
            }
            const res = await store.core.createPaymentIntent(data);
    
            console.log('==============api response======================');
            console.log(res);
            console.log('====================================');
            if (res.success) {
                setClientSecret(res.result.client_secret);
                setShow(true);
            } else {
                toast.error(res.message);
            }
            setTimeout(() => {
                btn_order?.removeAttribute('disabled');
            }, 3500);
            btn_order.innerText = 'Confirmer';
        } catch (error) {
            console.log(error);
            toast.error("Une erreur s'est produite");
        }
    }

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance
    } as any;
    
    useEffect(() => {
        fetchPlan();
        let url = `http://${hostname}/invoice`;
        // console.log("url",url);
        
    }, [fetchPlan, plan_id])



    return (
        <>
            <div className="row">
                {/* [ sample-page ] start */}
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <ul className="nav nav-tabs checkout-tabs mb-0" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="ecomtab-tab-3" data-bs-toggle="tab" href="#ecomtab-3" role="tab" aria-controls="ecomtab-3" aria-selected="true">
                                        <div className="media align-items-center">
                                            <div className="avtar avtar-s">
                                                <i className="ti ti-credit-card" />
                                            </div>
                                            <div className="media-body ms-3">
                                                <h5 className="mb-0">Paiement</h5>
                                                <p className="text-sm mb-0">{plan.name}</p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane active show" id="ecomtab-3" role="tabpanel" aria-labelledby="ecomtab-tab-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <h4 className="mb-3">{plan.name}</h4>
                                            <p className="description">
                                                {plan.description}
                                            </p>
                                            {!show && (
                                                <div className="d-flex align-items-center justify-content-between mb-3">
                                                    <Link to="/dashboard/plans" >
                                                        <button className="btn btn-link-primary"><i className="ti ti-arrow-narrow-left align-text-bottom me-2" />Annuler</button>
                                                    </Link>
                                                    <button className="btn btn-primary" id="btn_order" onClick={createIntent} >Confirmer le paiement</button>
                                                </div>
                                            )}

                                            {show && clientSecret && (
                                                <Elements options={options} stripe={stripePromise}>
                                                    <CheckoutForm />
                                                </Elements>
                                            )}

                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card border">
                                                <div className="card-header">
                                                    <div className="media align-items-center">
                                                        <div className="avtar avtar-s bg-light-secondary text-secondary">
                                                            <i className="ti ti-shopping-cart f-20" />
                                                        </div>
                                                        <div className="media-body ms-3">
                                                            <h5>Détails panier</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="media align-items-center">
                                                        <div className="media-body ms-3">
                                                            <h5 className="mb-1">{plan.name}</h5>
                                                            <p className="text-sm text-muted mb-0"></p>
                                                        </div>
                                                        <h5 className="mb-1">{plan.price} € </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card border">
                                                <div className="card-header">
                                                    <div className="media align-items-center">
                                                        <div className="avtar avtar-s bg-light-primary text-primary">
                                                            <i className="ti ti-clipboard-list f-20" />
                                                        </div>
                                                        <div className="media-body ms-3">
                                                            <h5>Résumé</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    <li className="list-group-item">
                                                        <div className="float-end">
                                                            <h5 className="mb-0">{plan.price} €</h5> </div><span className="text-muted">Sous Total</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <div className="float-end">
                                                            <h5 className="mb-0">-</h5> </div><span className="text-muted">Coupon </span>
                                                    </li>
                                                </ul>
                                                <div className="card-footer">
                                                    <div className="float-end">
                                                        <h5 className="mb-0">{plan.price} €</h5>
                                                    </div>
                                                    <h5 className="mb-0 d-inline-block">Total</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* [ sample-page ] end */}
            </div>

        </>
    )
}

export default CheckoutPage
