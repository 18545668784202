/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Plan } from '../../models';
import { useNavigate } from 'react-router-dom';

const PricingItem = ({ plan, index }: { plan: Plan, index: number}) => {

    const navigate = useNavigate();

    const checkoutPlan = (plan_id: number) => {
        if (plan_id !== null) {
            console.log(plan);
            navigate(`/dashboard/plans/checkout/${plan_id}`);
        }
    }

    return (
        <div className="card price-card active">
            <div className="card-body">
                <div className="price-icon">
                    <i className="ti ti-bus bg-light-primary text-primary" />
                </div>
                <h2 className="mt-4">{plan.name}</h2>
                <p className="mt-5">{plan.description}</p>
                <div className="price-price text-muted"> <sup>€</sup>{plan.price}<span>/mo</span></div>
                <ul className="list-group list-group-flush product-list">
                    {plan.features?.map((feature: string, index: number) => (
                        <li className="list-group-item" key={index}>{feature}</li>
                    ))}
                </ul>
                <button className="btn btn-primary bg-light text-primary mt-4" onClick={() => checkoutPlan(plan.id)}>
                    <i className="ti ti-shopping-cart me-2" /> Souscrire à ce plan 
                </button>
            </div>
        </div>
    );
}

export default PricingItem;
